const routes = [
  {
    index: true,
    label: 'Bassel Cheaib',
    path: '/',
  },
  {
    label: 'Portfolio',
    path: 'https://dev.basselch.com/projects',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
