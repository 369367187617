import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faBookmark } from '@fortawesome/free-regular-svg-icons/faBookmark';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'https://basselch.com',
    label: 'Website',
    icon: faBookmark,
  },
  {
    link: 'https://go.basselch.com/github',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://go.basselch.com/linkedin',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'https://go.basselch.com/telegram',
    label: 'Telegram',
    icon: faTelegram,
  },
  {
    link: 'https://go.basselch.com/email',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
