import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Bassel Cheaib</h2>
        <p>
          <a href="https://go.basselch.com/email">bassel.bc@gmail.com</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        Hi, I&apos;m Bassel. I&apos;m a skilled software engineer with five
        years of hands-on experience in the field. Over the years, I&apos;ve
        worked with local companies, international clients, and startups,
        building up a diverse skill set covering full-stack development, mobile
        development, and cybersecurity.
      </p>
      <ul className="actions">
        <li>
          {window.location.pathname === '/' ? (
            <Link to="/contact" className="button">
              Contact Me
            </Link>
          ) : (
            <Link to="/" className="button">
              Learn More
            </Link>
          )}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Bassel Cheaib{' '}
        <a href="https://basselch.com" target="_blank" rel="noreferrer">
          basselch.com
        </a>
        .
      </p>
    </section>
  </section>
);

export default SideBar;
